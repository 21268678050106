body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  background-color: #fdfdfd;
  color: white;
}

a {
  text-decoration: none;
  color: white;
}

/* Adjustments for smaller screens */
@media (max-width: 600px) {
  .container {
    padding: 10px;
  }

  .avatar {
    width: 20px;
    height: 20px;
  }
}

/* Adjustments for medium screens */
@media (min-width: 601px) and (max-width: 1024px) {
  .container {
    padding: 20px;
  }

  .avatar {
    width: 40px;
    height: 40px;
  }
}

/* Adjustments for large screens */
@media (min-width: 1025px) {
  .container {
    padding: 40px;
  }

  .avatar {
    width: 60px;
    height: 60px;
  }
}

@media (min-width: 768px) {
  .main-page {
    flex-direction: row;
    align-items: initial;
  }
}

.main-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh; /* Ensure it covers at least the full viewport height */
  width: 100vw;
  background: linear-gradient(to right, #c33764 0%, #1d2671 100%);
}

.how {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh; /* Ensure it covers at least the full viewport height */
  width: 100vw;
  background: linear-gradient(to right, #c33764 0%, #1d2671 100%);
}

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh; /* Ensure it covers at least the full viewport height */
  width: 100vw;
  background: linear-gradient(to right, #c33764 0%, #1d2671 100%);
}

